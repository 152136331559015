import * as React from "react";
import PropTypes from "prop-types";

const Pricing = ({ data }) => (
  <div className="columns">
    {data.map((plan) => (
      <div key={plan.plan} className="column">
        <section className="section">
          <h4 className="has-text-centered has-text-weight-semibold">
            {plan.plan}
          </h4>
          <p className="has-text-weight-semibold">{plan.description}</p>
          <ul>
            {plan.items.map((item) => (
              <li key={item} className="is-size-5">
                {item}
              </li>
            ))}
          </ul>
        </section>
      </div>
    ))}
  </div>
);

Pricing.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      description: PropTypes.string,
      items: PropTypes.array,
    })
  ),
};

export default Pricing;
